var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-lg":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg4":"","md12":"","sm12":"","pr-6":"","pl-lg-6":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Từ ngày","hint":"DD-MM-YYYY","persistent-hint":"","prepend-icon":"fa-calendar-alt","readonly":""},model:{value:(_vm.computedStartDateFormatted),callback:function ($$v) {_vm.computedStartDateFormatted=$$v},expression:"computedStartDateFormatted"}},on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuStart = false}},model:{value:(_vm.dateRange.startDate),callback:function ($$v) {_vm.$set(_vm.dateRange, "startDate", $$v)},expression:"dateRange.startDate"}})],1)],1)],1),_c('v-flex',{attrs:{"lg4":"","md12":"","sm12":"","pr-6":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Đến ngày","hint":"DD-MM-YYYY","persistent-hint":"","prepend-icon":"fa-calendar-alt","readonly":""},model:{value:(_vm.computedEndDateFormatted),callback:function ($$v) {_vm.computedEndDateFormatted=$$v},expression:"computedEndDateFormatted"}},on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuEnd = false}},model:{value:(_vm.dateRange.endDate),callback:function ($$v) {_vm.$set(_vm.dateRange, "endDate", $$v)},expression:"dateRange.endDate"}})],1)],1)],1),_c('v-flex',{attrs:{"lg4":"","md12":"","sm12":"","pr-6":""}},[_c('v-btn',{attrs:{"color":"#a77800","outlined":""},on:{"click":_vm.filter_data}},[_vm._v("Xem")])],1)],1)],1),_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":""}},[_c('highcharts',{attrs:{"options":_vm.chartOptions}})],1)],1)],1),_c('v-flex',{attrs:{"lg12":""}},[_c('v-card',{staticClass:"mb-8"},[_c('v-toolbar',{attrs:{"flat":"","color":"blue-grey","dark":""}},[_c('v-toolbar-title',[_vm._v("Tổng giao dịch")])],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":""}},[_c('v-data-table',{attrs:{"fixed-header":"","height":"750px","headers":_vm.headers,"hide-default-footer":"","sortable":false,"items":_vm.items,"server-items-length":_vm.items ? _vm.items.length : 0,"loading":_vm.loading,"mobile-breakpoint":100},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.ReportMonth.toString().replace(/(\d{4})(\d{2})/, "$2-$1")))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.OrderRevenue)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.DepositRevenue)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.PayRevenue)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.ComplainAmount)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.TotalRevenue)))])])]}},(_vm.summary)?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("TỔNG")]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.OrderRevenue)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.DepositRevenue)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.PayRevenue)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.ComplainAmount)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.summary.TotalRevenue)))])])]},proxy:true}:null],null,true)})],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"lg12":""}},[_c('v-card',{staticClass:"mb-8"},[_c('v-toolbar',{attrs:{"flat":"","color":"blue-grey","dark":""}},[_c('v-toolbar-title',[_vm._v("Doanh thu theo nhân viên")])],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":"","sm12":"","xs12":""}},[_c('v-data-table',{attrs:{"fixed-header":"","height":"750px","headers":_vm.summary_headers,"hide-default-footer":"","sortable":false,"items":_vm.staff_summary,"server-items-length":_vm.staff_summary ? _vm.staff_summary.length : 0,"loading":_vm.loading,"mobile-breakpoint":100},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.StaffName))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.RevenueCommissionVND)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.OrderCommission)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.OrderFeeCommission)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.DepositFeeCommission)))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(item.TotalValue)))])])]}},(_vm.staff_total)?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("TỔNG")]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.staff_total.RevenueCommissionVND)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.staff_total.OrderCommission)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.staff_total.OrderFeeCommission)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.staff_total.DepositFeeCommission)))]),_c('td',{staticClass:"font-weight-bold text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.staff_total.TotalValue)))])])]},proxy:true}:null],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }